import React from 'react'
import classNames from 'classnames'

export const Loader = ({
  className,
  fullscreen = false,
  isRelative,
  text,
  white,
  ...props
}) => {
  return (
    <div
      className={classNames(
        'loader',
        'spin-lg',
        white && 'loader--white',
        isRelative && 'loader--relative',
        fullscreen && 'loader--fullscreen',
        className
      )}
      {...props}
    >
      <span className="spin-dot spin-dot-spin">
        <i className="spin-dot-item"></i>
        <i className="spin-dot-item"></i>
        <i className="spin-dot-item"></i>
        <i className="spin-dot-item"></i>
      </span>
    </div>
  )
}
