export const SLUG = !window.location.hostname.includes('thisisalf') // whiteLabel
  ? window.location.hostname
  : window.location.hostname.split('.')[0]

export const BASE_URL =
  window.location.protocol +
  '//api.' +
  window.location.host.split('.').slice(-2).join('.')

export const APP_URL = BASE_URL + '/' + SLUG

export const GRAPHQL_ENDPOINT = APP_URL + '/graphql'
